<template>
<div class="transfer-equipment-apply-index" id="page">
  <statistical :data="statisticalData" v-loading="countLoading" />
  <div class="teai-list-part">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="search_key">
        <el-input v-model="form.search_key" placeholder="IOT/申请人姓名" clearable>
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="审核状态" prop="status">
        <el-select v-model="form.status" clearable placeholder="请选择" size="small">
          <el-option v-for="item in statusOption" :key="item.id" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="table.tableData" v-loading="table.tabelLoading">
      <el-table-column prop="txt_device_iot" label="IOT" align="center" />
      <el-table-column prop="txt_type_name" label="设备类型" align="center" />
      <el-table-column prop="dec_agent_amount_left" label="待返还保证金（元）" align="center">
        <template #default="scope">{{scope.row.dec_agent_amount_left||"- -"}}</template>
      </el-table-column>
      <el-table-column prop="txt_clinic_name" label="原诊所信息" align="center">
        <template #default="scope">
          <template v-if="scope.row.txt_clinic_name">{{scope.row.txt_clinic_name}}（{{scope.row.txt_clinic_agent_name}}）</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="txt_target_clinic_name" label="现诊所信息" align="center">
        <template #default="scope">
          <template v-if="scope.row.txt_target_clinic_name">{{scope.row.txt_target_clinic_name}}（{{scope.row.txt_target_clinic_agent_name}}）</template>
          <template v-else>- -</template>
        </template>
      </el-table-column>
      <el-table-column prop="txt_verify_status" label="申请状态" align="center" width="100">
        <template #default="scope">
          <template v-if="scope.row.txt_verify_status==1">待处理</template>
          <template v-else-if="scope.row.txt_verify_status==2">已取消</template>
          <template v-else-if="scope.row.txt_verify_status==7">已同意</template>
          <template v-else-if="scope.row.txt_verify_status==8">已拒绝</template>
        </template>
      </el-table-column>
      <el-table-column prop="txt_salesman_name" label="申请人" align="center">
        <template #default="scope">{{scope.row.txt_salesman_name||"- -"}}</template>
      </el-table-column>
      <el-table-column prop="dt_apply" label="申请时间" align="center">
        <template #default="scope">{{scope.row.dt_apply||"- -"}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="table-option">
        <template #default="scope">
          <div class="table-icon" :class="scope.row.txt_verify_status!=1?'disabled':''" @click="goApprove(scope.row, true)">
            <el-icon>
              <CircleCheck />
            </el-icon>同意
          </div>
          <div class="table-icon" :class="scope.row.txt_verify_status!=1?'disabled':''" @click="goApprove(scope.row, false)">
            <el-icon>
              <CircleClose />
            </el-icon>拒绝
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  </div>
</div>
</template>

<script>
// 设备管理 - 移交申请管理
//申请状态 1待处理 2业务员已取消 3市代同意 4市代取消 5省代同意 6省代取消 7公司同意 8公司取消
import { useStore } from 'vuex';
import { Statistical, Pagination } from "components";
import { CircleCheck, CircleClose } from "@element-plus/icons-vue";
import { onMounted, reactive, toRefs } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getTransferList, approveTransfer } from "api/apis.js";
export default {
  components: {
    Statistical,
    CircleCheck,
    CircleClose,
    Pagination
  },
  setup() {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      countLoading: false,
      total: 0,
      form: {
        search_key: null,
        status: "0",
        page: 1,
        limit: 10
      },
      statisticalData: [
        { key: "cnt_province", end: "个", value: 0, zn: "待审核数量", eg: "Quantity to be reviewed" }
      ],
      table: {
        tableLoading: false,
        tableData: []
      },
      statusOption: [
        { id: 1, value: "0", label: "未审核" },
        { id: 2, value: "1", label: "已审核" }
      ],
    });

    onMounted(() => {
      getList();
    });

    // 审批
    const goApprove = (item, type) => {
      let start = type ? '同意' : '拒绝',
        end = type ? '移交' : '拒绝';
      ElMessageBox.confirm(
        `请确认是否${start}移交，${end}后不可取消`,
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        // 确认
        approveTransfer({
          t_unbind_apply_id: item.t_unbind_apply_id,
          status: type ? 1 : 2,
          txt_updater: store.getters.userInfo.name || "系统用户",
          dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("操作成功");
            getList();
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.tabelLoading = false;
        })
      }).catch(() => {
        console.log("取消操作");
        state.table.tabelLoading = false;
      })
    };

    // 获取列表数据
    const getList = () => {
      state.table.tableLoading = true;
      for (let i = 0; i < Object.keys(state.form).length; i++) {
        let item = Object.keys(state.form)[i];
        if ((state.form[item] || "") == "") {
          delete state.form[item];
        }
      }
      getTransferList(state.form).then(response => {
        if (response.code == 200) {
          state.total = response.data.count;
          state.table.tableData = response.data.data;
          state.statisticalData[0].value = response.data.pending_count || 0;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tableLoading = false;
      })
    };

    // 重置表单
    const reset = () => {
      state.form = {
        search_key: null,
        status: "0",
        page: 1
      };
      getList();
    };

    return {
      ...toRefs(state),
      reset,
      getList,
      goApprove
    }
  }
}
</script>

<style lang="scss" scoped>
.transfer-equipment-apply-index {
  .teai-list-part {
    margin-top: 24px;
    height: calc(100% - 150px);
  }

  .el-table {
    height: calc(100% - 100px);
  }
}
</style>
